import * as React from "react";
import { Header, Container, Segment, Icon, Grid } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const AboutPage = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h2">
          <Icon name="info circle" />
          <Header.Content>
            About
          </Header.Content>
        </Header>
      </Segment>
    <Segment vertical className="aboutooti">
      <Grid stackable verticalAlign="middle" className="container">
        <Grid.Row>
          <Grid.Column width="4">
          <h2>Team</h2>
          </Grid.Column>          
        </Grid.Row>
        <Grid.Row>
          <h3>Amit Wellner - Founder</h3>
          <p>Amit has over 15 years of software development experience in various industries:
 Health care, Aerospace, Finance, Defense, Market Research, Marketing. Currently he is spearheading tooti development.</p>
        </Grid.Row>
        <Grid.Row>
          <h3>Galit Wellner, Ph.D. - Co-Founder</h3>
          <p>
          Dr. Galit Wellner is a philosopher of technology with an extensive background in the high tech industry.
          <br></br>
          Galit worked for start-ups as well as international corporates, 
          and served as a board member of the FTTH Council Europe. 
          <br></br>
          Today she teaches at Tel Aviv University 
          and researches the philosophical aspects of AI, autonomous vehicles and augmented reality.</p>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment vertical className="stripe">
      <Grid stackable verticalAlign="middle" className="container">
        <Grid.Row>
          <Grid.Column width="8">
          <h2>Contact Us:</h2>
        <p>
          For any question, please email us on <a href="mailto:info@tooti.cc" target="blank"> info@tooti.cc</a>
        </p>
          </Grid.Column>          
        </Grid.Row>
      </Grid>
    </Segment>
        
    </Container>
  );
};

export default withLayout(AboutPage);
